import React from 'react';
import Skills from '../components/Hero/Skills/Skills';
import About from '../components/Hero/About/About';
import Projects from '../components/Hero/Projects/Projects';
import Contact from '../components/Contact/Contact';
import './style.scss'
const HomePage = () => {


  return (
    <div id='home'>
      <section id="skills">
        <Skills />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="projects">
        <Projects />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
};

export default HomePage;
