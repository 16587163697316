import React from 'react'
import './footer.scss'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-wrapper'>
        <a href="/">
          <p>Developed by Vigan Zhuri © 2024</p>
        </a>
      </div>
    </div>
  )
}

export default Footer