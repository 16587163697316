import acm from '../img/project-img/ACM.png'
import acm1 from '../img/project-img/ACM1.png'
import acm2 from '../img/project-img/ACM2.png'
import edma from '../img/project-img/EDMA.png'
import edma1 from '../img/project-img/EDMA1.png'
import edma2 from '../img/project-img/EDMA2.png'
import real from '../img/project-img/REAL.png'
import real1 from '../img/project-img/REAL1.png'
import yc1 from '../img/project-img/YC1.png'
import yc2 from '../img/project-img/YC2.png'
import yc3 from '../img/project-img/YC3.png'
import yc4 from '../img/project-img/YC4.png'
import re1 from '../img/project-img/RE1.png'
import re2 from '../img/project-img/RE2.png'
import re3 from '../img/project-img/RE3.png'
import mod1 from '../img/project-img/MOD1.png'
import mod2 from '../img/project-img/MOD2.png'

const data={
    "projects":[
            {
            "id": 1,
            "images": [yc1, yc2, yc3, yc4],
            "title": "Youtube Clone",
            "sub-title": "Front-End",
            "description": "Check out my YouTube Clone – a responsive web app for seamless video streaming. Explore curated sections, custom categories, and dedicated channel pages. Enjoy uninterrupted video playback with Rapid API. Built with React.js and Axios for data fetching. Elevate your online video experience!",
            "tech": "React JS, Rapid Api",
            "github": "https://github.com/vigan2002/youtube-clone",
            "demo":"https://project4.viganzhuri.biz"
        },        
        {
            "id": 2,
            "images": [acm, acm1, acm2],
            "title": "Ac-Milan",
            "sub-title": "Front-End, CRUD",
            "description": "Dive into the excitement of football management with my React project inspired by FIFA and AC Milan. Explore the Create, Read, Update, and Delete functionalities to personalize player cards. Craft dream line-ups, fine-tune stats, and experience the dynamic world of virtual football in a user-friendly interface.",
            "tech": "React JS, JSON server",
            "github": "https://github.com/vigan2002/ac-milan",
            "demo":"https://project1.viganzhuri.biz"
        },
        {
            "id": 3,            
            "images": [real, real1],
            "title": "Real Estate",
            "sub-title": "Full Stack",
            "description": "Dynamic Real Estate Web App for a Prishtina-based company. Front-end crafted with React.js, employing Axios for data fetching. Initial data handled via JSON server; future plans include integrating a robust Django-Python backend for database connectivity.",
            "tech": "React JS, Django Python",
            "github": "https://github.com/vigan2002/real-estate-front"
        },
        {
            "id": 4,            
            "images": [edma, edma1, edma2],
            "title": "EDMA-GMBH",
            "sub-title": "Front-End",
            "description": "Under mentor Ardian Halili, I completed a pivotal project as the final test for my certification. This project focused on mastering modal creation and integration. I gained expertise in designing and implementing modals into web applications, demonstrating proficiency in user interface development for web-based platforms.",
            "tech": "React JS, JSON",
            "github": "https://github.com/vigan2002/edma-gmbh",
            "demo":"https://project2.viganzhuri.biz"
        },
        {
            "id": 5,            
            "images": [re1, re2, re3],
            "title": "Real-Estate App",
            "sub-title": "Front-End",
            "description": "Elevate your property search with my Next.js real estate web app, fueled by RapidAPI. Discover rental homes and for-sale properties effortlessly, featuring advanced filtering and immersive property details pages with image carousels. Your gateway to a seamless and visually engaging real estate experience.",
            "tech": "Next JS, Rapid Api",
            "github": "https://github.com/vigan2002/real-estate-RapidApi"
        },
        {
            "id": 6,            
            "images": [mod1, mod2],
            "title": "Modal App",
            "sub-title": "Front-End",
            "description": "In this project, I mastered modal creation and Axios for fetching online API data. I honed modal design/functionality skills and learned to seamlessly integrate modals into web apps. Using Axios, I adeptly retrieved real-time data, enhancing dynamic content display in web development.",
            "tech": "React, Axios",
            "github": "https://github.com/vigan2002/modal-axios"
        }
    ]
}
export{
    data
}