import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './projects.scss';
import { data } from '../../../assets/data/data';

const Projects = () => {

  const [projects] = useState(data.projects);
  const [visible, setVisible] = useState(3);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    dotsClass: 'slick-dots custom-dots',
    arrows: false,
  };

  const showMoreProjects = () => {
    setVisible(prevValue => prevValue + 3);
  }

  const showLessProjects = () => {
    const newValue = Math.max(3, visible - 3);
    setVisible(newValue);
  };

  return (
    <div className='projects'>
      <div className='projects-wrapper'>
        <h1>My projects</h1>
        <div className='projects-container'>
          {projects?.slice(0, visible).map((el) => (
            <div className='card' key={el.id}>
              <div className='card-images'>
                <Slider {...sliderSettings}>
                  {el.images.map((img, index) => (
                    <img src={img} alt={`project-${el.id}-img-${index}`} key={index} />
                  ))}
                </Slider>
              </div>
              <div className='card-bottom'>
                <div className='card-infos'>
                  <h1>{el.title}</h1>
                  <h2>{el['sub-title']}</h2>
                  <p>{el.description}</p>
                  <h4>{el.tech}</h4>
                </div>
                <div className='card-buttons'>
                  <a href={el.github}><button>View Code</button></a>
                  {el.demo && <a href={el.demo}><button>Demo</button></a>}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='load-button'>
          <button onClick={showMoreProjects} >Load More</button>
          <button onClick={showLessProjects} >Show Less</button>
        </div>
      </div>
    </div>
  );
};

export default Projects;
