import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './contact.scss';
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaBehanceSquare } from "react-icons/fa";
import { motion } from 'framer-motion'


const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_xxcw0pd',
            'template_zrerbvh',
            form.current,
            'GhfK8IapI53rAtcPG'
        )
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                form.current.reset();
            }, (error) => {
                console.log(error.text);
            });
    };

    const infoVariants = {
        initial: {
            opacity: 0,
            scale: .8
        },
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 1,
            }
        }
    }

    return (
        <div className="contact">
            <div className='contact-wrapper'>
                <h1>Contact me</h1>
                <motion.div
                    className='contact-form'
                    variants={infoVariants}
                    initial="initial"
                    whileInView="animate"
                >
                    <div className='info'>
                        <h2>Address:</h2>
                        <p>Prishtine, Kosovo</p>
                        <h2>Telephone:</h2>
                        <p>+383 43 749 712</p>
                        <h2>Email:</h2>
                        <p>viganzhuri2002@gmail.com</p>
                        <div className='icons'>
                            <a href="https://www.linkedin.com/in/vigan-zhuri-06b759202/">
                                <FaLinkedin />
                            </a>
                            <a href="https://github.com/vigan2002">
                                <FaGithub />
                            </a>
                            <a href="https://www.behance.net/viganzhurii">
                                <FaBehanceSquare />
                            </a>
                            <a href="https://www.instagram.com/vigan.zhuri/">
                                <FaInstagram />
                            </a>
                        </div>
                    </div>
                    <div className='contact-form-wrapper'>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className='user-wrapper'>
                                <div>
                                    <label>Name</label>
                                    <input type="text" name="user_name" />
                                </div>
                                <div>
                                    <label>Email</label>
                                    <input type="email" name="user_email" />
                                </div>
                            </div>
                            <label>Subject</label>
                            <input type="text" name="user_subject" />
                            <label>Message</label>
                            <textarea name="message" />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Contact;