import React, { useState } from 'react';
import './navbar.scss';
import { FaConnectdevelop } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { motion } from 'framer-motion'

const Navbar = () => {
  const [toggleBtn, setToggleBtn] = useState(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setToggleBtn(false);
    }
  };

  const handleToggle = () => {
    setToggleBtn(!toggleBtn);
  };

  return (
    <div className={`navbar ${toggleBtn ? 'mobile-menu' : ''}`}>
      <div className='navbar-wrapper'>
        <motion.div
          className='navbar-logo'
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <a href='/'>
            <FaConnectdevelop />
          </a>
          ViganZhuri
        </motion.div>
        <div className={`navbar-list ${toggleBtn ? 'show-mobile-menu' : ''}`}>
          <ul>
            <li>
              <button onClick={() => scrollToSection('skills')}>Skills</button>
            </li>
            <li>
              <button onClick={() => scrollToSection('about')}>About</button>
            </li>
            <li>
              <button onClick={() => scrollToSection('projects')}>Projects</button>
            </li>
            <li>
              <button onClick={() => scrollToSection('contact')}>Contact</button>
            </li>
          </ul>
        </div>
        <button className='mobile-menu-toggle' onClick={handleToggle}>
          {toggleBtn ? <IoClose /> : <FaBars />}
        </button>
      </div>
    </div>
  );
};

export default Navbar;
