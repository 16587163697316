import React, { useState, useEffect } from 'react'
import './skills.scss'
import vigan from '../../../assets/img/vigan-pic.jpeg'
import { motion } from 'framer-motion'

const Skills = () => {

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = ["React Developer"];
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta)
    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);
    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2)
    }
    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(500);
    }
  }

  const txtVariants = {
    initial: {
      x:-500,
      opacity: 0,
      scale: .5
    },
    animate: {
      x: 0,
      opacity:1,
      scale: 1,
      transition:{
        duration: 1,
      }
    }
  }

  return (
    <div className='skills'>
      <div className='skills-wrapper'>
        <div className='skills-container'>
          <div className='skills-img'>
            <img src={vigan} alt="vigan" />
          </div>
          <div className='skills-info'>
            <h1>Front-End <br /> <span className='wrap'>{text}</span></h1>
            <p>Hi, I'm <span>Vigan Zhuri.</span> A passionate Front-end React Developer based in Prishtina, Kosovo.</p>
          </div>
        </div>
        <motion.div
          className='skills-tech'
          variants={txtVariants}
          initial="initial"
          animate="animate"
        >
          <h2>Tech Stack </h2>
          <div className='skills-cards'>
            <div className='card'>
              <h1>Front-End</h1>
              <motion.p 
                initial={{ opacity: 0 }}
                transition={{ delay:1.2, duration: .5 }}
                animate={{ opacity: 1 }}
              > HTML5 / CSS3 / Javascript / Typescript /
                React / Redux / next.js / SASS / Tailwind css /
                Bootstrap / Mui / Chakra
              </motion.p>
            </div>
            <div className='card'>
              <h1>Back-End</h1>
              <motion.p 
                initial={{ opacity: 0 }}
                transition={{ delay:1.2, duration: .5 }}
                animate={{ opacity: 1 }}
              > MySQL / MongoDbB / JAVA / .Net / Node.js
              </motion.p>
            </div>
            <div className='card'>
              <h1>Other</h1>
              <motion.p 
                initial={{ opacity: 0 }}
                transition={{ delay:1.2, duration: .5 }}
                animate={{ opacity: 1 }} 
              >  Git / Github / Netlify / Postman / Vercel
              </motion.p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Skills