import React from 'react';
import './about.scss';
import cv from '../../../assets/img/CV.jpeg';
import pdfFile from '../../../assets/ViganZhuriCv.pdf';
import {motion} from 'framer-motion'
 
const About = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfFile;
    link.download = 'ViganZhuriCV.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const slideVariants = {
    initial: {
      opacity: 0,
      scale: .5
    },
    animate: {
      opacity:1,
      scale: 1,
      transition:{
        duration: 1,
      }
    }
  }

  return (
    <div className='about'>
      <motion.div 
        className='about-wrapper'
        variants={slideVariants}
        initial="initial"
        whileInView="animate"
      >
        <div className='about-txt'>
          <h1>About Me</h1>
          <h2>I am an enthusiastic Front end developer</h2>
          <p>passionate about JavaScript and specializing in React.js to create engaging web solutions. Skilled in HTML, CSS, .NET, and MySQL, I'm currently pursuing a Computer Engineering degree at UBT College. I'm also broadening my backend skills with Node.js and am dedicated to delivering innovative, user-centered applications.</p>
        </div>
        <div className='about-img'>
          <img src={cv} alt="cv"/>
          <button onClick={handleDownload}>Download CV</button>
        </div>
      </motion.div>
    </div>
  );
};

export default About;
